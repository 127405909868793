// Check demo at https://www.react-spinners.com/
export const BAR = 'BarLoader';
export const BEAT = 'BeatLoader';
export const BOUNCE = 'BounceLoader';
export const CIRCLE = 'CircleLoader';
export const CLIP = 'ClipLoader';
export const CLIMBINGBOX = 'ClimbingBoxLoader';
export const DOT = 'DotLoader';
export const FADE = 'FadeLoader';
export const GRID = 'GridLoader';
export const HASH = 'HashLoader';
export const MOON = 'MoonLoader';
export const PACMAN = 'PacmanLoader';
export const PROPAGATE = 'PropagateLoader';
export const PULSE = 'PulseLoader';
export const RING = 'RingLoader';
export const RISE = 'RiseLoader';
export const ROTATE = 'RotateLoader';
export const SCALE = 'ScaleLoader';
export const SYNC = 'SyncLoader';
