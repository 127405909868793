import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { debounce } from "lodash";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { createBrowserHistory } from "history";
//custom import
import UserModel from "../../models/userModel";
import { execGetUserInfoByPatientCode} from "../../actions/services/api-user";
import * as MSG from '../../constants/Messages';
import { GET_REPORT_LIST } from '../../actions/types';
import { execGetReportList } from '../../actions/services/api-report';
import { execGetCompanyReportList } from '../../actions/services/api-company';
import { createAction } from 'redux-actions';
import Spinner from '../../components/Spinner/Spinner';
import {GOLDEN_HEALTH_ORANGE} from '../../constants/Colors';
import {HASH} from '../../constants/Loaders';
import './style.scss';


let history = createBrowserHistory();
const getReports = createAction(GET_REPORT_LIST);
const mapDispatchToProps = dispatch => ({
  loadUserByPatientCode: (data) => dispatch(execGetUserInfoByPatientCode(data)),
  execGetReportList: data => dispatch(execGetReportList(data)),
  execGetCompanyReportList: companyId => dispatch(execGetCompanyReportList(companyId)),
  getReports: data => dispatch(getReports(data)),
  getReportDetails: paramStr => dispatch({type: 'RTE_CHI_TIET_KHAM_BENH', payload:{ paramStr }}),
});

const mapStateToProps = ({ services, location }) => ({
  user: services.user.userInfo,
  reports: services.report.reportList,
  location: location,
  isAuthenticated: services.auth.authencation.authenticated
});
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  header: {
    fontSize: 16,
    cursor: 'default'
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  row: {
    cursor: 'pointer'
  },
  borderForm: {
    marginTop: 10,
    marginLeft: 10, 
    marginRight: 10
  }
});

class XemBenhSu extends React.Component {

  state = {
      loading: false,
      reports: [],
      visibility: [true, true, true],
      patientCode:'',
      userEmail:'',
      userPhone: '',
      searchText: '',
      user: new UserModel(),
  };

  initPage = (mayte) => {
    let user = { ...this.state.user };
    this.setState({searchText: mayte});
    if(typeof user['maYte'] != "undefined"){
      user['maYte'] = mayte;
      this.setState({ user: new UserModel(user) });
    }else{
      let state = { ...this.state };
      state['maYte'] = mayte;
      this.setState(state);
    }
    this.setState({reports: [], loading: true});
    this.getUserInfo(user['maYte']);
  }

  SearchUser = (evt) => {
    var searchText = evt.target.value; // this is the search text
    let user = { ...this.state.user };
    this.setState({searchText: searchText})
    if(typeof user['maYte'] != "undefined"){
      user['maYte'] = evt.target.value;
      this.setState({ user: new UserModel(user) });
    }else{
      let state = { ...this.state };
      state['maYte'] = evt.target.value;
      this.setState(state);
    }


    // if(this.timeout) clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //   //search function
    //   this.getUserInfo(user['maYte']);

    // }, 500);
  }

  handleKeyPress =(evt) => {
    if(evt.key === 'Enter'){
      this.setState({reports: [], loading: true});
      let user = { ...this.state.user };
      this.getUserInfo(user['maYte']);
      
    }
  }
  
  getUserInfo = (code) => {
    //this.setState({reports: [], loading: true});
    this.props.loadUserByPatientCode({ code: code })
      .then(data => {
        if(data.status === 200){
          // TODO: remove temporary fix for this issue
          let user =  new UserModel(data.json);
          user.ten = user.getFullName();
          this.setState({ user });
          this.initializeScreen();
          history.push('/xem-benh-su?mayte=' + user.maYte);
        } else if(data.status === 404){
          this.setState({loading: false});
          toast.error('Không tìm thấy mã y tế này');
        }
      })
      .catch(err => this.setState({loading: false}))
  };

  componentDidMount() {
    this.mounted = true;
    const queryParams = new URLSearchParams(window.location.search)
    const mayte = queryParams.get('mayte');
    if(mayte && mayte != '') {
      this.initPage(queryParams.get('mayte'));
    }
    
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  initializeScreen = async () => {
    try{
      const { user } = this.props;
      let result = user.phanQuyen === 3 ? await this.getCompanyReports(user) : await this.getPersonalReports(this.state.user);
      if(result && result.status === 200){
        this.props.getReports(result.json);
        const list = this.groupDataList(result.json);
        if(!this.mounted) return;
        this.setState({reports: [...list], loading: false});
      }

    }catch(err){
      this.handleError({detail: err, message: MSG.ERROR_OCCURED});
    };
  };

  getPersonalReports = async (user) => {
    if(!user.benhNhanId) return this.handleError({detail: null, message: MSG.USER_NO_PATIENT_ID});
    return this.props.execGetReportList(user.benhNhanId);
  };

  getCompanyReports = async (user) => {
    if(!user.donViCongTacId) return this.handleError({detail: null, message: MSG.USER_NO_COMPANY_ID});
    return this.props.execGetCompanyReportList(user.donViCongTacId);
  };

  handleError = err => {
    if(!this.mounted) return;
    toast.error(err.message);
    console.error(err.detail);
    this.setState({loading: false});
  };

  groupDataList = (data) => {

    if(Array.isArray(data)){
      let parents = [];
      let childs = [];
      data.forEach((item) => {
        if(item.parentGroupId){
          childs[item.parentGroupId] ? childs[item.parentGroupId].push(item) : childs[item.parentGroupId] = [item];
        }else{
          parents.push(item);
        }
      });

      parents.forEach((parent, index) => {
        if(childs[parent.groupId]){
          parents[index].children = [..._.orderBy(childs[parent.groupId], ['name'], ['asc'])];
        }
      });

      return parents;
    }
    return [];
  };

  handleShowInfo = (paramStr) => {
    if (paramStr)
      this.props.getReportDetails(encodeURIComponent(paramStr));
  };

  toggleList = (index) => {
    this.setState((prevState)=>{
      let visibility = [...prevState.visibility];
      visibility[index] = !visibility[index];
      return {visibility}
    })
  };

  render() {

    const { classes } = this.props;
    const { loading, reports, visibility } = this.state;

    return (
      <Paper className={`XemBenhSu ${classes.root}`}>
        <Spinner type={HASH} size={50} color={GOLDEN_HEALTH_ORANGE} loading={loading}/>
        <form className={classes.borderForm} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField label="Mã Y Tế" variant="outlined" value={this.state.searchText} fullWidth onChange={(evt) => this.SearchUser(evt)} onKeyPress={(evt) => this.handleKeyPress(evt)}/>
          </Grid>
          <Grid item xs={4}>
            <TextField label="Họ tên" variant="outlined" value={this.state.user.getFullName()} fullWidth disabled />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Ngày sinh" variant="outlined" value={this.state.user.getFormattedBirthday()} fullWidth disabled />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Giới tính" variant="outlined" value={this.state.user.getGenderName()} fullWidth disabled />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Địa chỉ" variant="outlined" value={this.state.user.diaChi} fullWidth disabled />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Phone" variant="outlined" value={this.state.user.phone} fullWidth disabled />
          </Grid>
          
        </Grid>
        </form>
        <Table className={classes.table}>
          <TableBody>
            {reports.length === 0 && <TableRow className={`${classes.row}`}>
                                        <TableCell className={classes.header} align="center">Không có dữ liệu!</TableCell>
                                      </TableRow>}

            {reports.map((parent, index) => {
              const { children } = parent;
              return(
                <React.Fragment key={`parent-${index}`}>
                  <TableRow
                    onClick={() => this.toggleList(index)}
                    className={`${classes.row}`}>
                    <TableCell component="td" scope="row" colSpan={12}>
                      <div className="ParentGroup">
                        { !!visibility[index] ? <span className="fa fa-chevron-up ParentGroupIcon"/> : <span className="fa fa-chevron-down ParentGroupIcon"/>}
                        {parent.name}
                      </div>
                      <Collapse in={!!visibility[index]}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.header} align="left">Mã nhóm</TableCell>
                              <TableCell className={classes.header} align="left">Ngày thực hiện</TableCell>
                              <TableCell className={classes.header} align="left">Năm</TableCell>
                              <TableCell className={classes.header} align="left">Kết quả</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              children ?
                                children.map((report, cIndex) => (
                                  <TableRow
                                    key={`child-${index}-${cIndex}`}
                                    onClick={() => this.handleShowInfo(report.paramStr)}
                                    className={`${!report.paramStr ? classes.disabled : classes.row}`}
                                    title={!report.paramStr ? MSG.NO_DETAIL_AVAILABLE : MSG.SHOW_DETAIL}>
                                    <TableCell align="left">{report.name}</TableCell>
                                    <TableCell align="left">{report.ngayThucHien}</TableCell>
                                    <TableCell align="left">{report.nam}</TableCell>
                                    <TableCell align="left">{report.isKetQua ? 'Đã khám' : 'Đang đợi'}</TableCell>
                                  </TableRow>
                                  )
                                )
                                :
                                <TableRow>
                                  <TableCell align="center" colSpan={12}>Không có dữ liệu</TableCell>
                                </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

XemBenhSu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(XemBenhSu));
