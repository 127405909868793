import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import { createAction } from 'redux-actions';
import { USER } from '../../constants/User';
//custom import
import vietnamese from '../../locales/vietnamese';
import { BOUNCE } from '../../constants/Loaders';
import Spinner from '../../components/Spinner';
import { GOLDEN_HEALTH_ORANGE } from '../../constants/Colors';
import { SET_STAFF_LIST } from '../../actions/types';

import { execGetListUserStaff } from "../../actions/services/api-user";


import * as MSG from '../../constants/Messages.js';

const setStaffList = createAction(SET_STAFF_LIST);

const mapDispatchToProps = dispatch => ({
  saveToStore: data => dispatch(setStaffList(data)),
  gotoUpdateStaffPage: id => dispatch({ type: 'RTE_STAFF_UPDATE', payload: { id } }),
  getStaffUser: () => dispatch(execGetListUserStaff()),
});

const mapStateToProps = ({ services }) => ({
  staffs: services.staff.staffList
});

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  header: {
    fontSize: 16,
  },
  buttonActive: {
    backgroundColor: '#2698D6',
    color: '#fff',
  }
});

class DsNhanVien extends React.Component {

  state = {
    loading: true,
  };

  componentWillMount() {
    this.initScreen();
  }

  initScreen = async () => {
    try{
      let staffs = [];
      const usersRes = await this.props.getStaffUser();

      if(usersRes.status === 200){
        staffs = usersRes.json;
        this.handleSuccess(staffs);
      }

      this.setState({loading: false})

    }catch(e){
      this.handleError(e);
    }
  };
  handleAction = async (user, action) => {
    if(!user && !user.userId) return;
    switch (action) {
      case USER.ACTION.UPDATE:
        this.props.gotoStaffDetails(user.userId)
        break;
      default:
    }
  };

  handleSuccess = (staffList) => {
    this.props.saveToStore(staffList);
    this.setState({loading: false})
  };


  gotoDetails(id){
    this.props.gotoUpdateStaffPage(id);
  }

  render() {

    const { classes, staffs } = this.props;
    const { loading } = this.state;
    const columns = [
      { title: 'Tên nhân viên', field: 'ten', render: staff =>  staff.ten },
      { title: 'Điện thoại', field: 'phone', render: staff =>  staff.phone },
      { title: 'Email', field: 'email', render: staff =>  staff.email },
      { title: 'Xem chi tiết', sorting: false, render: staff =>  <Button variant="contained" className={classes.buttonActive} onClick={this.gotoDetails.bind(this, staff.userId)}>Sửa</Button> },
    ];
    return (
      <Paper className={classes.root}>
        <Spinner type={BOUNCE} size={50} color={GOLDEN_HEALTH_ORANGE} loading={loading} />
        <MaterialTable
            localization={vietnamese}
            columns={columns}
            data={staffs}
            options= {{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50, 100],
              debounceInterval: 200,
              showTitle: false,
              emptyRowsWhenPaging: false,
              filtering: false,
            }}
        />
      </Paper>
    );
  }
}

DsNhanVien.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DsNhanVien));
