import { handleActions } from 'redux-actions';
import {
  SET_STAFF_LIST
} from '../../actions/types'
import { combineReducers } from 'redux'

const staffListDefault = [];

const staffList = handleActions({
  [SET_STAFF_LIST]: {
    next(state, action) {
      return action.payload ? action.payload : state;
    }
  }
}, staffListDefault)

export default combineReducers({ staffList })
