import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { BOUNCE } from '../../constants/Loaders';
import { USER } from '../../constants/User';
import Spinner from '../../components/Spinner';
import { GOLDEN_HEALTH_ORANGE } from '../../constants/Colors';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';

import { confirmAlert } from 'react-confirm-alert';
//custom import
import { GET_USER_LIST } from '../../actions/types';
import { execGetUserList, execDeleteUser, execAdminApprove } from '../../actions/services/api-user';
import { createAction } from 'redux-actions';
import * as MSG from '../../constants/Messages.js';

import vietnamese from '../../locales/vietnamese';

import ActivatePatientPostModel from "../../models/activatePatientPostModel";

const getUsers = createAction(GET_USER_LIST);
const mapDispatchToProps = dispatch => ({
  getUserList: (pageNumber, code, email, phone) => { return dispatch(execGetUserList(pageNumber, code, email, phone)) },
  saveUserListToStore: data => dispatch(getUsers(data)),
  gotoUserDetailsPage: id => dispatch({ type: 'RTE_CHI_TIET_USER', payload: { id } }),
  gotoUpdateRegularUserPage: id => dispatch({ type: 'RTE_USER_UPDATE', payload: { id } }),
  gotoUpdateCompanyUserPage: id => dispatch({type: 'RTE_CAP_NHAT_USER_COMPANY', payload: { id }}),
  gotoReviewUserPage: id => dispatch({ type: 'RTE_KIEM_TRA_USER', payload: { id } }),
  deleteUser: id => dispatch(execDeleteUser(id)),
  adminApprove: id => dispatch(execAdminApprove(id)),
});

const mapStateToProps = ({ services, location }) => {
    return {
      users: services.user.userList,
      currentUser: services.user.userInfo,
      location: location,
    }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  header: {
    fontSize: 16,
  },
  deleteIcon: {
    fontSize: 20,
  },
  buttonActive: {
    backgroundColor: "#2698D6",
    color: "#fff",
  }
});

class FormDanhSachUser extends React.Component {
    constructor(props) {
       super(props);
       this.changeUserSearch = this.changeUserSearch.bind(this);
    }
  state = {
    loading: true,
    totalCount: 1000,
    pageSize: 20,
    currentPage: 1,
    userCode:'',
    userEmail:'',
    userPhone: '',
    typingTimeout: 0
  };

  changeUserSearch = (evt, typeSearch) => {
      var searchText = evt.target.value; // this is the search text
      this.setState({[typeSearch]: searchText})
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        //search function
        if(typeSearch === 'userCode') {
            this.fetchUserList(this.state.currentPage, searchText, this.state.userEmail, this.state.userPhone);
        } else if(typeSearch === 'userEmail'){
            this.fetchUserList(this.state.currentPage, this.state.userCode, searchText, this.state.userPhone);
        } else {
            this.fetchUserList(this.state.currentPage, this.state.userCode, this.state.userEmail, searchText);
        }


      }, 500);
  }

  componentDidMount() {
    this.fetchUserList(0, '', '', '');
  }

  fetchUserList = async (pageNumber, code, email, phone) => {
    try{
        if(pageNumber > 0) {
            this.setState({loading: true})
        }
      const result = await this.props.getUserList(pageNumber, code, email, phone);
      const totalCount = result.json.totalCount
      const pageSize = result.json.pageSize
      const currentPage = result.json.currentPage
      const userList = result.json.listData
      if(result.status === 200) this.props.saveUserListToStore(_.orderBy(_.map(userList, user => new ActivatePatientPostModel(user)), function(user){return user.getFormattedRegisterDate()}, ['desc']));
      this.setState({loading: false, totalCount: totalCount, pageSize: pageSize, currentPage: currentPage});
    }catch (e) {
      this.handleError({detail: e, message: MSG.ERROR_OCCURED});
    }
  };

  handleAction = async (user, action) => {
    if(!user && !user.userId) return;
    switch (action) {
      case USER.ACTION.DETAIL:
        this.props.gotoUserDetailsPage(user.userId);
        break;
      case USER.ACTION.UPDATE:
        user.phanQuyen === USER.ROLE.COMPANY ? this.props.gotoUpdateCompanyUserPage(user.userId) : this.props.gotoUpdateRegularUserPage(user.userId);
        break;
      case USER.ACTION.ACTIVATE:
        this.props.gotoReviewUserPage(user.userId);
        break;
      case USER.ACTION.DELETE:
        this.showDeleteConfirmation(user.userId);
        break;
      default:
    }
  };

  showDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className='custom-ui'>
              <h1>Xác nhận</h1>
              <p>Bạn thực sự muốn xóa user này ?</p>
              <button onClick={onClose}>Không</button>
              <button
                  onClick={() => {
                    this.handleDeleteUser(id);
                    onClose();
                  }}
              >
                Đúng vậy!
              </button>
            </div>
        );
      }
    });
  };

  handleDeleteUser = async (id) => {
    try{
      this.setState({loading: true});
      const result = await this.props.deleteUser(id);
      if(result.status === 200){
        this.handleSuccess(MSG.USER.DELETE.SUCCESS);
        this.fetchUserList(0, '', '', '');
        return;
      }

      this.handleError({detail: result, message: MSG.USER.DELETE.FAILED});
    }catch (e) {
      this.handleError({detail: e, message: MSG.USER.DELETE.FAILED});
    }
  };

  handleSuccess = message => {
    this.setState({loading: false});
    toast.success(message);
  };

  handleError = error => {
    this.setState({loading: false});
    toast.error(error.message);
    console.error(error.detail);
  };

  filterByFullName = (term, rowData) => rowData.getFullName().toLowerCase().indexOf(term.toLowerCase()) !== -1;

  filterByRegisterDate = (term, rowData) => rowData.getFormattedRegisterDate().toLowerCase().indexOf(term.toLowerCase()) !== -1;

  filterByUserStatus = (term, rowData) => rowData.getStatusName().toLowerCase().indexOf(term.toLowerCase()) !== -1;

  render() {
    const { classes, users, currentUser } = this.props;
    const { loading, totalCount, currentPage, pageSize, userCode } = this.state;
    console.log(userCode)
    const columns = [
      { title: 'Tên user', field: 'ten', render: user =>  user.getFullName(), customFilterAndSearch: this.filterByFullName },
      { title: 'Mã y tế', field: 'maYte' },
      { title: 'Ngày sinh', field: 'ngaySinh', render: user =>  user.getFormattedBirthday() },
      { title: 'Email', field: 'email', sorting: false},
      { title: 'Số điện thoại', field: 'phone', sorting: false},
      { title: 'Trạng thái', field: 'trangThai', render: user =>  user.getStatusName(), customFilterAndSearch: this.filterByUserStatus },
      { title: 'Quyền', field: 'phanQuyen', render: user =>  user.getRoleName()},
      { title: 'Ngày đăng ký', field: 'ngayDangKy', render: user =>  user.getFormattedRegisterDate(), customFilterAndSearch: this.filterByRegisterDate },
      { title: 'Active User', field: 'activeUser', sorting: false, render: user => {
        return user.trangThai === USER.STATUS.ACTIVE.CODE ? <div className={classes.deleteIcon }>
          <i className="fa fa-info-circle" style={{ paddingRight: 10, color: '#2698D6' }} onClick={() => this.handleAction(user, USER.ACTION.DETAIL) } />
          <i className="fa fa-pencil-square-o" style={{ paddingRight: 10, color: 'green' }} onClick={() => this.handleAction(user, USER.ACTION.UPDATE) } />
        </div> :
          <div>
            {
              currentUser.userId === user.userId ?
                null : <Button variant="contained" className={classes.buttonActive} disabled={user.trangThai !== USER.STATUS.PENDING_ADMIN.CODE} onClick={() => this.handleAction(user, USER.ACTION.ACTIVATE) }>Duyệt</Button>
            }
          </div>
      }},
      { title: 'Xóa', field: 'delete', sorting: false, render: user => {
        return <div> { currentUser.userId === user.userId ?
          null : <i className="fa fa-times-circle" style={{ paddingRight: 10, color: 'red', fontSize: 21 }} onClick={() => this.handleAction(user, USER.ACTION.DELETE) } />
        }</div>
      }},
    ];
    return (
      <Paper className={classes.root}>
        <Spinner type={BOUNCE} size={50} color={GOLDEN_HEALTH_ORANGE} loading={loading} />
        <div className="search-form">
            <label>Mã y tế</label>
            <input type="text" value={this.state.userCode} onChange={(evt) => this.changeUserSearch(evt, 'userCode')}/>
            <label className="marginLeft10">Email</label>
            <input type="text" value={this.state.userEmail} onChange={(evt) => this.changeUserSearch(evt, 'userEmail')}/>
            <label className="marginLeft10">Phone</label>
            <input type="text" value={this.state.userPhone} onChange={(evt) => this.changeUserSearch(evt, 'userPhone')}/>
        </div>
        <MaterialTable
          localization={vietnamese}
          columns={columns}
          data={users}
          components={{
              Pagination: props => <TablePagination {...props} count={totalCount} page={currentPage}
              onChangePage={(e, page) => { /* handle page changes */
                  this.fetchUserList(page, '', '', '')
              }}
              onChangeRowsPerPage={(event) => { /* handle page size change : event.target.value */}}/>
          }}
          // data={ query => return {data: users, page: currentPage, totalCount: totalCount} }
          options= {{
            pageSize: pageSize,
            pageSizeOptions: [],
            debounceInterval: 200,
            showTitle: false,
            emptyRowsWhenPaging: false,
            filtering: false,
          }}
        />
      </Paper>
    );
  }
}

FormDanhSachUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormDanhSachUser));
